<template>
  <b-container class="mt-4 mb-5" fluid>
    <div class="d-flex justify-content-end">
      <b-button variant="secondary" class="mb-3" :to="{ name: 'users.create'}" v-if="checkPermission('Create User - Users') || checkPermission('Create Content Creator - Users')">+ Create User</b-button>
    </div>
    <b-card class="justify-content-center bs-br">
      <b-table hover :items="users.data" :fields="fields" :busy="load" responsive tbody-tr-class="transition-tr" table-class="table-users">
        <template #table-busy>
          <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(full_name)="row">
          {{row.item.name+' '+row.item.last_name}}
        </template>
        <template #cell(verified)="row">
          <vue-fontawesome icon="check-circle" size="1.5" :color="!row.item.email_verified_at ? 'green': '#DBDBDB'"/>
        </template>
        <template #cell(status)="row">
          <b-badge variant="success" v-if="row.item.status">Enabled</b-badge>
          <b-badge variant="warning" v-else>Disabled</b-badge>
        </template>
        <template #cell(roles)="row">
          <b-badge variant="info" v-for="(value, key) in row.item.roles" :key="key">{{value}}</b-badge>
        </template>
        <template #cell(actions)="row">
          <b-button variant="default" v-if="checkPermission('Edit User - Users') || checkPermission('Edit Content Creator - Users') && !isSuperAdmin(row.item.roles)" @click="$router.push({name: 'users.edit', params: {user: row.item.id}})">
            <vue-fontawesome icon="edit" size="1.5" color="#DBDBDB"/>
          </b-button>
          <b-button variant="default" v-if="checkPermission('User Permissions - Users') && !isSuperAdmin(row.item.roles)" @click="getUserPermissions(row.item.id)">
            <vue-fontawesome icon="key" size="1.5" color="#DBDBDB"/>
          </b-button>
        </template>
      </b-table>
      <b-pagination
          v-model="users.pagination.current_page"
          pills
          align="right"
          :total-rows="users.pagination.total"
          :per-page="users.pagination.per_page"
          @change="getUsers"
          v-if="users.data.length > 0"
      ></b-pagination>
    </b-card>


    <b-modal v-model="permissionsUser" size="lg" hide-header>
      <b-container class="pb-2 pt-2">
        <h3>Roles & Permissions</h3>
        <!-- Roles -->
        <b-row class="mt-4">
          <label>Roles</label>
          <b-col v-for="(role, keyRole) in AllRoles" :key="keyRole" cols="12" xl="6" lg="6" md="12" sm="12">
            <b-card no-body class="mt-2 p-2 card-roles">
              <b-card-header>
                <b-form-checkbox name="checkbox-1" :value="role.name" v-model="listPermissions.listRoles">
                  {{role.name}}
                </b-form-checkbox>
              </b-card-header>
            </b-card>
          </b-col>
        </b-row>
        <!-- Permissions -->
        <b-row class="mt-4">
          <label>Permissions</label>
          <b-col v-for="(module, keyModule) in AllPermissions" :key="keyModule" cols="12" md="12" lg="6" xl="6">
            <b-card no-body class="mt-2 p-2 card-roles">
              <b-card-header v-b-toggle="'accordion-'+(module.name).split(' ').join('-')">
                <h6 class="m-0">{{module.name}}</h6>
                <vue-fontawesome icon="chevron-up" class="when-open"></vue-fontawesome>
                <vue-fontawesome icon="chevron-down" class="when-closed"></vue-fontawesome>
              </b-card-header>
              <b-card-body>
                <b-collapse :id="'accordion-'+(module.name).split(' ').join('-')" accordion="permissions-accordion" role="tabpanel">
                  <small>Assigned permissions</small>
                  <b-form-group>
                    <b-form-checkbox-group v-model="listPermissions.listPermissions" :options="module.permissions"
                      name="permissions-user" stacked></b-form-checkbox-group>
                  </b-form-group>
                </b-collapse>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer="{ close }">
        <b-overlay
          :show="loadPermissions"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
          >
          <b-button size="sm" variant="primary" @click="savePermissionsToUser">
            Save permissions
          </b-button>
        </b-overlay>

          <b-button size="sm" variant="secondary" @click="close()">
              Cancel
          </b-button>
      </template>
    </b-modal>


    <!-- <b-modal v-model="permissionsUser" size="lg" hide-header>
      <b-tabs content-class="mt-3" justified>
        <b-tab title="Roles" active>
          <b-container class="pb-2">
            <b-row>
                <b-col v-for="(role, keyRole) in AllRoles" :key="keyRole" cols="12" xl="6" lg="6" md="12" sm="12">
                    <b-card no-body class="mt-2 p-2 card-roles">
                        <b-card-header>
                            <b-form-checkbox
                                name="checkbox-1"
                                :value="role.name"
                                v-model="listPermissions.listRoles"
                            >
                                {{role.name}}
                            </b-form-checkbox>
                            <span v-b-toggle="'accordion-'+keyRole">
                                <vue-fontawesome icon="chevron-up" class="when-open"></vue-fontawesome>
                                <vue-fontawesome icon="chevron-down" class="when-closed"></vue-fontawesome>
                            </span>
                        </b-card-header>
                        <b-card-body>
                            <b-collapse :id="'accordion-'+keyRole" accordion="roles-accordion" role="tabpanel">
                                <small>{{role.modules.length > 0 ? 'Modules' : 'No permissions assigned'}}</small>
                                <b-card no-body class="mt-2 p-2 card-roles" v-for="(module, keyModule) in role.modules" :key="keyModule">
                                    <b-card-header v-b-toggle="'accordion-'+(module.name).split(' ').join('-')+'-'+role.name.replace(' ', '')">
                                        <h6 class="m-0">{{module.name}}</h6>
                                        <vue-fontawesome icon="chevron-up" class="when-open"></vue-fontawesome>
                                        <vue-fontawesome icon="chevron-down" class="when-closed"></vue-fontawesome>
                                    </b-card-header>
                                    <b-card-body>
                                        <b-collapse :id="'accordion-'+(module.name).split(' ').join('-')+'-'+role.name.replace(' ', '')" accordion="permissions-accordion" role="tabpanel">
                                            <small>Assigned permissions</small>
                                            <b-list-group>
                                                <b-list-group-item v-for="(permission, keyPermission) in module.permissions" :key="keyPermission">{{permission.text}}</b-list-group-item>
                                            </b-list-group>
                                        </b-collapse>
                                    </b-card-body>
                                </b-card>
                            </b-collapse>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
          </b-container>
        </b-tab>
        <b-tab title="Permissions">
          <b-container class="pb-2">
            <b-row>
              <b-col v-for="(module, keyModule) in AllPermissions" :key="keyModule" cols="12" md="12" lg="6" xl="6">
                <b-card no-body class="mt-2 p-2 card-roles">
                    <b-card-header v-b-toggle="'accordion-'+(module.name).split(' ').join('-')">
                        <h6 class="m-0">{{module.name}}</h6>
                        <vue-fontawesome icon="chevron-up" class="when-open"></vue-fontawesome>
                        <vue-fontawesome icon="chevron-down" class="when-closed"></vue-fontawesome>
                    </b-card-header>
                    <b-card-body>
                        <b-collapse :id="'accordion-'+(module.name).split(' ').join('-')" accordion="permissions-accordion" role="tabpanel">
                            <small>Assigned permissions</small>
                            <b-form-group>
                                <b-form-checkbox-group
                                    v-model="listPermissions.listPermissions"
                                    :options="module.permissions"
                                    name="permissions-user"
                                    stacked
                                ></b-form-checkbox-group>
                            </b-form-group>
                        </b-collapse>
                    </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-container>
        </b-tab>
      </b-tabs>
      <template #modal-footer="{ close }">
        <b-overlay
          :show="loadPermissions"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
          >
          <b-button size="sm" variant="primary" @click="savePermissionsToUser">
            Save permissions
          </b-button>
        </b-overlay>

          <b-button size="sm" variant="secondary" @click="close()">
              Cancel
          </b-button>
      </template>
    </b-modal> -->
  </b-container>
</template>
<script>
export default {
  name: 'Users',

  data() {
    return {
      users: {
        data: [],
        pagination: {
          current_page: 0,
          per_page: 0,
          total: 0
        }
      },
      permissionsUser: false,
      loadPermissions: false,
      listPermissions: {
          user: 0,
          listRoles: [],
          listPermissions: []
      },
      AllRoles: [],
      AllPermissions: [],
      fields: [
        { key: 'full_name', label: 'Full Name' },
        { key: 'email', label: 'E-Mail', sortable: true },
        { key: 'verified', label: 'Verified' },
        { key: 'status', label: 'Status' },
        { key: 'roles', label: 'Roles' },
        { key: 'created_at', label: 'Created'},
        { key: 'last_signin', label: 'Last login'},
        { key: 'actions', label: '', class: 'd-flex'}
      ],
      load: true
    };
  },

  mounted() {
    if(this.checkPermission('List Users - Users')) {
      this.getUsers();
    }else if(this.checkPermission('List Content Creator - Users')) {
      this.getContentCreators();
    }
    this.getRolesAndPermissions();
  },

  methods: {
    getUsers(page){
      this.load = true;
      this.$http.get('user', { params: {page: page}, headers: { Authorization: `Bearer ${this.$session.get('jwt')}` } }).then((response) => {
        if (response.status === 200) {
          this.users = response.body.data;
        }
        this.load = false;
      }, (error) => {
        if (error.status == 500) {
          this.$notify({ group: 'app', text: 'An error has occurred, please try again.', type: 'error' });
        }
      })
    },
    getContentCreators(page){
      this.$http.get('getContentCreators', { params: {page: page}, headers: { Authorization: `Bearer ${this.$session.get('jwt')}` } }).then((response) => {
        if (response.status === 200) {
          this.users = response.body.data;
        }
        this.load = false;
      }, (error) => {
        console.log(error)
      })
    },
    getRolesAndPermissions(){
      this.$http.get('role', {headers: {"Authorization": "Bearer "+this.$session.get('jwt')}}).then((response) => {
        if (response.status == 200) {
          response.body.data.roles.forEach((role) => {
            this.AllRoles.push({
              name: role.name,
              modules: this.getModules(role.permissions)
            })
          });
          this.AllPermissions = this.getModules(response.body.data.permissions);
        }
      }, (error) => {
        if (error.status == 500) {
          this.$notify({ group: 'notifications_app', type: 'error', text: 'An error has occurred, please try again.', duration: 10000 });
        }
      });
    },
    isSuperAdmin(roles){
      return roles.includes('Super Admin');
    },
    getUserPermissions(user){
      this.$http.get('getUserPermissions/'+user, {headers: {"Authorization": "Bearer "+this.$session.get('jwt')}}).then(response => {
        if (response.status == 200) {
          this.listPermissions.listRoles = response.body.data.roles;
          this.listPermissions.listPermissions = response.body.data.permissions;
          this.listPermissions.user = user;
          this.permissionsUser = true;
        }
      }, error => {
        if (error.status == 500) {
          this.$notify({ group: 'notifications_app', type: 'error', text: 'An error has occurred, please try again.', duration: 10000 });
        }
        this.loadPage = false;
      });
    },
    savePermissionsToUser(){
      this.loadPermissions = true;
      this.$http.post('saveUserPermissions', this.listPermissions, {headers: {"Authorization": "Bearer "+this.$session.get('jwt')}}).then((response) => {
        if (response.status == 200) {
            this.$notify({ group: 'notifications_app', type: 'success', text: response.body.message });
            this.permissionsUser = false;
            this.getUsers();
        }
        this.loadPermissions = false;
      }, (error) => {
        if (error.status == 500) {
            this.$notify({ group: 'notifications_app', type: 'error', text: 'An error has occurred, please try again.', duration: 10000 });
        }
        this.loadPermissions = false;
      });
    },
  },
};
</script>
